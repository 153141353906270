import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { useSelector } from 'src/hooks/useSelector';
import { NovaSort } from 'src/models/search/vacancy/sorts';

import { useDebouncedCountsRequestMobile } from 'src/components/Search/Common/Filters/hooks/useSendFilterForm';

const setSearchSortsAction = makeSetStoreField('novaSorts');

type UseApplySortHook = <F extends keyof NovaSort>(sortKey: F) => (value: NovaSort[F]) => void;

export const useApplySort: UseApplySortHook = (sortKey) => {
    const dispatch = useDispatch();
    const novaSorts = useSelector((state) => state.novaSorts);
    const sendCountsRequest = useDebouncedCountsRequestMobile();

    return useCallback(
        (value) => {
            const newSorts = { ...novaSorts };
            newSorts[sortKey] = value;
            dispatch(setSearchSortsAction(newSorts));
            sendCountsRequest();
        },
        [dispatch, sortKey, novaSorts, sendCountsRequest]
    );
};
