import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { FormLabel, VSpacing } from '@hh.ru/magritte-ui';
import { CompensationFrequency as CompensationFrequencyType } from '@hh.ru/types-hh-microcore';
import { ElementSpyInstance } from 'bloko/common/elementSpy';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterSelect from 'src/components/Search/Common/Filters/FilterSelect';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useFilterGroups } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import ChipsSelect from 'src/components/Search/Vacancy/Filters/FilterChips/Select';
import { ChipsSelectType } from 'src/components/Search/Vacancy/Filters/FilterChips/types';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EMPTY_CLUSTER } from 'src/models/search/common/clusters';
import { ClusterKey } from 'src/types/search/common/clusters';
import { UXFeedback } from 'src/utils/uxfeedback';

const EMPTY_ARRAY = [] as const;

const sendUXFeedback = () => UXFeedback.sendEvent('apply_compensation_frequency_filter');

const filterShownAnalytics = (element: HTMLElement): ElementSpyInstance =>
    Analytics.sendHHEventElementShown(element, {
        name: 'filter_compensation_frequency',
    });

const TrlKeys = {
    title: 'searchVacancy.compensationFrequency.title',
    label: 'searchVacancy.select.label',
    apply: 'searchVacancy.select.apply',
    reset: 'searchVacancy.select.reset',
};

const CompensationFrequency: TranslatedComponent = ({ trls }) => {
    const { groups, selectedValues } =
        useSelector((state) => state.searchClusters[ClusterKey.CompensationFrequency]) || EMPTY_CLUSTER;
    const orders = useSelector((state) => state.searchClustersOrder[ClusterKey.CompensationFrequency]) || EMPTY_ARRAY;

    const { items } = useFilterGroups(groups, selectedValues.length, orders);

    const applyFilter = useApplyFilter(ClusterKey.CompensationFrequency);

    if (!Object.keys(groups).length) {
        return null;
    }

    return (
        <ElementShownAnchor fn={filterShownAnalytics}>
            <MobileViewControl
                mobileView={
                    <>
                        <FormLabel>{trls[TrlKeys.title]}</FormLabel>
                        <VSpacing default={12} />
                        <ChipsSelect
                            name={ClusterKey.CompensationFrequency}
                            selected={selectedValues}
                            options={Object.values(groups)}
                            selectType={ChipsSelectType.Checkbox}
                            onChange={(value) => {
                                sendUXFeedback();
                                applyFilter(value);
                            }}
                        />
                        <VSpacing default={24} />
                    </>
                }
            >
                <FilterWrapper title={trls[TrlKeys.title]}>
                    <FilterSelect
                        name={ClusterKey.CompensationFrequency}
                        selectedValues={selectedValues}
                        items={items}
                        onChange={(values) => {
                            sendUXFeedback();
                            applyFilter(values as CompensationFrequencyType[]);
                        }}
                    />
                </FilterWrapper>
            </MobileViewControl>
        </ElementShownAnchor>
    );
};

export default translation(CompensationFrequency);
