import { useCallback } from 'react';

import { useSearchSettings } from 'src/hooks/search/useSearchSettings';
import { UXFeedback } from 'src/utils/uxfeedback';

import {
    useDebouncedCountsRequestMobile,
    useDebouncedSendFilterFormDesktop,
} from 'src/components/Search/Common/Filters/hooks/useSendFilterForm';

export const useApplyFilters = (): (() => void) => {
    const debouncedCountsRequestMobile = useDebouncedCountsRequestMobile();
    const debouncedSendFilterFormDesktop = useDebouncedSendFilterFormDesktop();

    const { isResumeSearchType, isVacancySearchType } = useSearchSettings();

    return useCallback(() => {
        if (!isResumeSearchType) {
            debouncedCountsRequestMobile();
        }
        debouncedSendFilterFormDesktop();

        if (isVacancySearchType) {
            UXFeedback.sendEvent('apply_vacancy_search_filter');
        }
    }, [debouncedCountsRequestMobile, debouncedSendFilterFormDesktop, isResumeSearchType, isVacancySearchType]);
};
