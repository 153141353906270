import { useMemo } from 'react';

import { sortByOrders } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import { SubIndustryClusterGroup, SubIndustryClusterGroupMap } from 'src/types/search/common/clusters';

interface GroupFilteredByParent {
    [key: string]: SubIndustryClusterGroup[];
}

export default (
    childrenGroups: SubIndustryClusterGroupMap,
    parentField: 'industryId',
    orders: string[]
): GroupFilteredByParent => {
    return useMemo(() => {
        // group by parentField
        const groupsByParent = Object.values(childrenGroups).reduce((map, group) => {
            if (!map[group[parentField]]) {
                map[group[parentField]] = [];
            }
            map[group[parentField]].push(group);
            return map;
        }, {} as GroupFilteredByParent);

        // sort by selected values
        const childrenMap = {} as GroupFilteredByParent;
        Object.keys(groupsByParent).forEach((parent) => {
            const values = Object.values(groupsByParent[parent]);
            sortByOrders(values, orders);
            childrenMap[parent] = [...values];
        });
        return childrenMap;
    }, [childrenGroups, orders, parentField]);
};
