import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { ElementSpyInstance } from 'bloko/common/elementSpy';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterContent from 'src/components/Search/Common/Filters/FilterContent';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import AcceptTemporary from 'src/components/Search/Vacancy/Filters/AcceptTemporary';
import Internship from 'src/components/Search/Vacancy/Filters/Internship';
import MobileFilter from 'src/components/Search/Vacancy/Filters/MobileFilter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ClusterKey, SearchVacancyLabelCluster } from 'src/types/search/common/clusters';
import { LabelOption } from 'src/types/search/vacancy/criteria';

const filterShownAnalytics = (element: HTMLElement): ElementSpyInstance =>
    Analytics.sendHHEventElementShown(element, {
        name: 'filter_employment_form',
    });

const TrlKeys = {
    title: 'searchVacancy.employmentForm.title',
};

const EmploymentForm: TranslatedComponent = ({ trls }) => {
    const acceptTemporaryCluster = useSelector((state) => state.searchClusters[ClusterKey.AcceptTemporary]);
    const labelCluster = useSelector((state) => state.searchClusters[ClusterKey.Label]) as
        | SearchVacancyLabelCluster
        | undefined;
    const internshipGroup = labelCluster?.groups?.[LabelOption.Internship];
    const isAdditionalFiltersVisible = Boolean(acceptTemporaryCluster) || Boolean(internshipGroup);

    return (
        <>
            <ElementShownAnchor fn={filterShownAnalytics}>
                <MobileViewControl
                    mobileView={
                        <MobileFilter
                            name={ClusterKey.EmploymentForm}
                            title={trls[TrlKeys.title]}
                            withDivider={isAdditionalFiltersVisible}
                        />
                    }
                >
                    <FilterWrapper title={trls[TrlKeys.title]} withDivider={isAdditionalFiltersVisible}>
                        <FilterContent name={ClusterKey.EmploymentForm} />
                    </FilterWrapper>
                </MobileViewControl>
            </ElementShownAnchor>
            {isAdditionalFiltersVisible && (
                <>
                    <VSpacingContainer default={12} gteM={0}>
                        <AcceptTemporary />
                        <Internship />
                    </VSpacingContainer>
                    <VSpacing default={24} />
                </>
            )}
        </>
    );
};

export default translation(EmploymentForm);
