import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { FormLabel, VSpacing } from '@hh.ru/magritte-ui';
import { WorkingHours as WorkingHoursType } from '@hh.ru/types-hh-microcore';
import { ElementSpyInstance } from 'bloko/common/elementSpy';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterSelect from 'src/components/Search/Common/Filters/FilterSelect';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useFilterGroups } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import NightShifts from 'src/components/Search/Vacancy/Filters/NightShifts';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EMPTY_CLUSTER } from 'src/models/search/common/clusters';
import { ClusterKey } from 'src/types/search/common/clusters';

const EMPTY_ARRAY = [] as const;

const filterShownAnalytics = (element: HTMLElement): ElementSpyInstance =>
    Analytics.sendHHEventElementShown(element, {
        name: 'filter_working_hours',
    });

const TrlKeys = {
    title: 'searchVacancy.workingHours.title',
    label: 'searchVacancy.select.label',
    apply: 'searchVacancy.select.apply',
    reset: 'searchVacancy.select.reset',
};

const WorkingHours: TranslatedComponent = ({ trls }) => {
    const { groups, selectedValues } =
        useSelector((state) => state.searchClusters[ClusterKey.WorkingHours]) || EMPTY_CLUSTER;
    const orders = useSelector((state) => state.searchClustersOrder[ClusterKey.WorkingHours]) || EMPTY_ARRAY;

    const { items } = useFilterGroups(groups, selectedValues.length, orders);

    const applyFilter = useApplyFilter(ClusterKey.WorkingHours);

    const isSelectExist = !!Object.keys(groups).length;

    return (
        <>
            <ElementShownAnchor fn={filterShownAnalytics}>
                <MobileViewControl
                    mobileView={
                        <>
                            <FormLabel>{trls[TrlKeys.title]}</FormLabel>
                            <VSpacing default={12} />
                            {isSelectExist && (
                                <>
                                    <FilterSelect
                                        name={ClusterKey.WorkingHours}
                                        selectedValues={selectedValues}
                                        items={items}
                                        onChange={(values) => {
                                            applyFilter(values as WorkingHoursType[]);
                                        }}
                                    />
                                    <VSpacing default={12} />
                                </>
                            )}
                        </>
                    }
                >
                    <FilterWrapper title={trls[TrlKeys.title]} withoutSpacing>
                        {isSelectExist && (
                            <>
                                <FilterSelect
                                    name={ClusterKey.WorkingHours}
                                    selectedValues={selectedValues}
                                    items={items}
                                    onChange={(values) => {
                                        applyFilter(values as WorkingHoursType[]);
                                    }}
                                />
                                <VSpacing default={12} />
                            </>
                        )}
                    </FilterWrapper>
                </MobileViewControl>
            </ElementShownAnchor>
            <NightShifts />
            <VSpacing default={24} gteM={12} />
        </>
    );
};

export default translation(WorkingHours);
