import { useState, useEffect, useCallback, useRef, useMemo, ReactElement } from 'react';

import {
    Cell,
    CellText,
    Modal,
    TreeSelector,
    Action,
    Button,
    ActionBar,
    NavigationBar,
    BottomSheet,
    BottomSheetFooter,
    CellRightLabel,
    ListControls,
    VSpacing,
    Loader,
    AdditionalDefault,
    TreeCollection,
} from '@hh.ru/magritte-ui';
import { ChevronLeftOutlinedSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslationHOCProps } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const DATA_QA_ITEMS = 'search-filter-tree-selector-items';

const TrlKeys = {
    selectAllParent: 'search.filters.treeselector.selectAllParent',
    searchPlaceholder: 'search.filters.treeselector.search.placeholder',
    actions: {
        apply: 'search.filters.treeselector.actions.apply',
        cancel: 'search.filters.treeselector.actions.cancel',
        clear: 'search.filters.treeselector.actions.clear',
    },
};

interface FilterTreeSelectorProps<T extends AdditionalDefault> {
    title: string;
    isVisible: boolean;
    setVisible: (value: boolean) => void;
    collection: TreeCollection<T>;
    selectedValues: Array<string>;
    applyFilter: (values: Array<string>) => void;
    shouldSelectChildValuesOnly?: boolean;
    counts?: Record<string, number>;
}

const FilterTreeSelector = function <T extends AdditionalDefault>({
    title,
    isVisible,
    setVisible,
    collection,
    selectedValues,
    applyFilter,
    shouldSelectChildValuesOnly,
    counts,
    trls,
}: TranslationHOCProps & FilterTreeSelectorProps<T>): ReactElement | null {
    const controlsRef = useRef<ListControls>(null);
    const [userSelectedValues, setUserSelectedValues] = useState(selectedValues);
    useEffect(() => setUserSelectedValues(selectedValues), [selectedValues]);

    const handleChangeSelection = useCallback(
        (values: string[]) => setUserSelectedValues(values),
        [setUserSelectedValues]
    );

    const handleApplySelection = useCallback(() => {
        if (shouldSelectChildValuesOnly) {
            const newSet = new Set<string>();
            userSelectedValues.forEach((item) => {
                if (collection.hasChildren(item)) {
                    collection.getChildrenIds(item).forEach((childId) => newSet.add(childId));
                } else {
                    newSet.add(item);
                }
            });
            applyFilter([...newSet]);
        } else {
            applyFilter(userSelectedValues);
        }
    }, [collection, applyFilter, userSelectedValues, shouldSelectChildValuesOnly]);

    const handleCancelSelection = useCallback(
        () => setUserSelectedValues(selectedValues),
        [setUserSelectedValues, selectedValues]
    );

    const handleApply = useCallback(() => {
        setVisible(false);
        handleApplySelection();
    }, [setVisible, handleApplySelection]);

    const handleCancel = useCallback(() => {
        setVisible(false);
        handleCancelSelection();
    }, [setVisible, handleCancelSelection]);

    const inputProps = useMemo(() => ({ placeholder: trls[TrlKeys.searchPlaceholder], clearable: true }), [trls]);

    if (!collection) {
        return (
            <>
                <VSpacing default={12} />
                <Loader size={24} />
            </>
        );
    }

    const buttonApply = (
        <Button mode="primary" style="accent" onClick={handleApply} data-qa="search-filter-tree-selector-apply">
            {trls[TrlKeys.actions.apply]}
        </Button>
    );

    const buttonCancel = (
        <Button mode="secondary" style="accent" onClick={handleCancel}>
            {trls[TrlKeys.actions.cancel]}
        </Button>
    );

    const actionCancel = <Action icon={CrossOutlinedSize24} onClick={handleCancel} />;

    return (
        <TreeSelector
            collapseToParentId
            collection={collection}
            value={userSelectedValues}
            onChange={handleChangeSelection}
            getSelectAllParentTrl={() => trls[TrlKeys.selectAllParent]}
            ref={controlsRef}
            renderItemForDesktop={({ input, item }) => {
                const count = counts?.[item.id] || 0;
                return (
                    <Cell
                        Element="label"
                        align="top"
                        left={input}
                        right={count ? <CellRightLabel hideIcon>{count}</CellRightLabel> : undefined}
                    >
                        <CellText>{item.text}</CellText>
                    </Cell>
                );
            }}
        >
            {({ renderTreeSelector, renderInput }) => (
                <>
                    <Modal
                        visible={isVisible}
                        title={title}
                        actions={actionCancel}
                        options={renderInput(inputProps)}
                        onClose={handleCancel}
                        footer={
                            <ActionBar
                                type="modal"
                                primaryActions={
                                    <>
                                        {buttonCancel}
                                        {buttonApply}
                                    </>
                                }
                                secondaryActions={
                                    <Button mode="tertiary" style="accent" onClick={() => handleChangeSelection([])}>
                                        {trls[TrlKeys.actions.clear]}
                                    </Button>
                                }
                            />
                        }
                        data-qa={DATA_QA_ITEMS}
                    >
                        {renderTreeSelector()}
                    </Modal>
                    <BottomSheet
                        visible={isVisible}
                        height="full-screen"
                        header={
                            <NavigationBar
                                title={title}
                                options={renderInput(inputProps)}
                                right={actionCancel}
                                left={
                                    <Action
                                        icon={ChevronLeftOutlinedSize24}
                                        onClick={() => controlsRef.current?.back()}
                                    />
                                }
                            />
                        }
                        footer={
                            <BottomSheetFooter>
                                {buttonApply}
                                <Button mode="secondary" style="accent" onClick={() => handleChangeSelection([])}>
                                    {trls[TrlKeys.actions.clear]}
                                </Button>
                            </BottomSheetFooter>
                        }
                        onClose={handleCancel}
                        data-qa={DATA_QA_ITEMS}
                    >
                        {renderTreeSelector()}
                    </BottomSheet>
                </>
            )}
        </TreeSelector>
    );
};

export default translation(FilterTreeSelector);
