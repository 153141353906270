import { useCallback, useMemo, useState } from 'react';

import { FormLabel, Link, VSpacing, fromTree } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterTreeSelector from 'src/components/Search/Common/Filters/TreeSelector';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import SelectedIndustry from 'src/components/Search/Vacancy/Filters/IndustryAndSubIndustry/SelectedIndustry';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EMPTY_CLUSTER } from 'src/models/search/common/clusters';
import { ClusterKey } from 'src/types/search/common/clusters';

const TrlKeys = {
    title: 'resumeBuilder.industry',
    add: 'novaFilters.industryAndSubIndustry.add',
};

const IndustryMobile: TranslatedComponent = ({ trls }) => {
    const industryTree = useSelector((state) => state.industriesTree);
    const collection = useMemo(() => fromTree(industryTree), [industryTree]);
    const { selectedValues } = useSelector((state) => state.searchClusters[ClusterKey.Industry]) || EMPTY_CLUSTER;

    const applyFilter = useApplyFilter(ClusterKey.Industry);
    const [isVisible, setVisible] = useState<boolean>(false);

    const handleClear = useCallback(
        (id: string) => {
            const newIndustry = selectedValues.filter((value) => {
                const [parentId] = value.split('.');
                return parentId !== id;
            });
            applyFilter(newIndustry);
        },
        [applyFilter, selectedValues]
    );

    return (
        <>
            <FormLabel>{trls[TrlKeys.title]}</FormLabel>
            <VSpacing default={12} />
            <SelectedIndustry selectedValues={selectedValues} collection={collection} onClear={handleClear} />
            <VSpacing default={12} />
            <Link
                iconLeft={<PlusOutlinedSize16 />}
                Element="button"
                data-qa="novafilters-mobile-add-industries"
                onClick={() => setVisible(true)}
            >
                {trls[TrlKeys.add]}
            </Link>
            <VSpacing default={24} />
            <FilterTreeSelector
                title={trls[TrlKeys.title]}
                isVisible={isVisible}
                setVisible={setVisible}
                collection={collection}
                applyFilter={applyFilter}
                selectedValues={selectedValues}
            />
        </>
    );
};

export default translation(IndustryMobile);
