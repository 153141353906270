import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { ElementSpyInstance } from 'bloko/common/elementSpy';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useHasClusterGroups } from 'src/components/Search/Common/Filters/hooks/useHasClusterGroups';
import AdaptiveViewFilter from 'src/components/Search/Vacancy/Filters/AdaptiveViewFilter';
import translation from 'src/components/translation';
import { ClusterKey } from 'src/types/search/common/clusters';

const TrlKeys = {
    title: 'searchVacancy.workFormat.title',
};

const filterShownAnalytics = (element: HTMLElement): ElementSpyInstance =>
    Analytics.sendHHEventElementShown(element, {
        name: 'filter_work_format',
    });

const WorkFormat: TranslatedComponent = ({ trls }) => {
    // TODO: удалить проверку на наличие кластера вместе с аналитикой
    const hasCluster = useHasClusterGroups(ClusterKey.WorkFormat);
    if (!hasCluster) {
        return null;
    }

    return (
        <ElementShownAnchor fn={filterShownAnalytics}>
            <AdaptiveViewFilter title={trls[TrlKeys.title]} name={ClusterKey.WorkFormat} />
        </ElementShownAnchor>
    );
};

export default translation(WorkFormat);
