import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';

import {
    Button,
    Card,
    Cell,
    CellRightLabel,
    CellText,
    RenderOptionTypeProps,
    Select,
    VSpacing,
    findStaticDataFetcherItemByValue,
    StaticDataFetcherItem,
    useStaticDataProvider,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { ClusterGroup, ClusterKey } from 'src/types/search/common/clusters';

import styles from './styles.less';

const renderFormattedValue = (value: ReactNode, index: number) => (
    <span className={classnames({ [styles.triggerValue]: index !== 0 })}>
        {index !== 0 && ', '}
        {value}
    </span>
);

interface FilterSelectItem extends StaticDataFetcherItem {
    count: number;
}

const TrlKeys = {
    label: 'searchVacancy.select.label',
    apply: 'searchVacancy.select.apply',
    reset: 'searchVacancy.select.reset',
    postfix: 'searchVacancy.select.postfix',
};

interface FilterSelectProps {
    name:
        | typeof ClusterKey.CompensationFrequency
        | typeof ClusterKey.WorkingHours
        | typeof ClusterKey.WorkScheduleByDays;
    selectedValues: string[];
    items: Array<ClusterGroup>;
    onChange: (values: string[]) => void;
}

const FilterSelect: TranslatedComponent<FilterSelectProps> = ({ name, selectedValues, items, onChange, trls }) => {
    const [key, setKey] = useState(0);
    const isResetActiveRef = useRef(!!selectedValues.length);

    useEffect(() => {
        isResetActiveRef.current = !!selectedValues.length;
    }, [selectedValues]);

    // При обновлении айтемов селект запоминает ранее выбранные значения, в данном кейсе это приводит к багу
    // TODO: удалить после https://jira.hh.ru/browse/PORTFOLIO-39258
    useEffect(() => {
        setKey((prevKey) => prevKey + 1);
    }, [items]);

    const selectItems: FilterSelectItem[] = useMemo(() => {
        if (!items) {
            return [];
        }
        return items.map(({ id, title, disabled, count }) => ({
            value: id,
            text: title,
            disabled,
            count,
        }));
    }, [items]);

    const renderDesktopItem = useCallback(({ input, data }: RenderOptionTypeProps<FilterSelectItem>) => {
        return (
            <Cell
                disabled={data.disabled}
                Element="div"
                align="top"
                left={input}
                right={data.count ? <CellRightLabel hideIcon>{data.count}</CellRightLabel> : undefined}
            >
                <CellText maxLines={1}>{data.text}</CellText>
            </Cell>
        );
    }, []);

    const selectDataProvider = useStaticDataProvider(selectItems);

    const selectValue = useMemo(
        () => findStaticDataFetcherItemByValue(selectedValues, selectItems),
        [selectItems, selectedValues]
    );

    return (
        <Select
            key={key}
            multiple
            type="checkbox"
            value={selectValue}
            name={name}
            dataProvider={selectDataProvider}
            onChange={(values: FilterSelectItem[]) => onChange(values.map(({ value }) => value))}
            onSelectOption={(values) => (isResetActiveRef.current = !!values.length)}
            headerTitle={trls[TrlKeys.label]}
            triggerProps={{
                size: 'medium',
                label: trls[TrlKeys.label],
                stretched: true,
                showPostfix: true,
                trls: { postfix: trls[TrlKeys.postfix] },
                renderFormattedValue,
            }}
            applyChangesButton={
                <Button mode="primary" style="accent">
                    {trls[TrlKeys.apply]}
                </Button>
            }
            clearButton={
                <Button mode="secondary" style="accent">
                    {trls[TrlKeys.reset]}
                </Button>
            }
            renderDesktopItem={renderDesktopItem}
            maxHeight={415}
            onDropClose={() => {
                isResetActiveRef.current = !!selectedValues.length;
            }}
            renderContentBefore={({ clearSelectedValuesAndClose }) => (
                <>
                    <Card
                        borderWidth="default"
                        stretched
                        hoverStyle="secondary"
                        padding={16}
                        borderRadius={16}
                        onClick={() => {
                            clearSelectedValuesAndClose();
                            isResetActiveRef.current = false;
                        }}
                        disabled={!isResetActiveRef.current}
                    >
                        <Cell align="center" left={<CrossOutlinedSize24 />}>
                            <CellText>{trls[TrlKeys.reset]}</CellText>
                        </Cell>
                    </Card>
                    <VSpacing default={16} />
                </>
            )}
        />
    );
};

export default translation(FilterSelect);
