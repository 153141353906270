import { FC } from 'react';

import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';

import Industry from 'src/components/Search/Vacancy/Filters/IndustryAndSubIndustry/Industry';
import IndustryMobile from 'src/components/Search/Vacancy/Filters/IndustryAndSubIndustry/IndustryMobile';
import SubIndustry from 'src/components/Search/Vacancy/Filters/IndustryAndSubIndustry/SubIndustry';

const IndustryAndSubIndustry: FC = () => {
    return (
        <MobileViewControl mobileView={<IndustryMobile />}>
            <Industry />
            <SubIndustry />
        </MobileViewControl>
    );
};

export default IndustryAndSubIndustry;
