import Analytics from '@hh.ru/analytics-js';

import { SearchType } from 'src/types/search/common';
import { ClusterKey, SearchClusterMap, SelectedValuesTypeByClusterKey } from 'src/types/search/common/clusters';

const FILTER_ANALYTICS_LABELS: Partial<Record<ClusterKey, string>> = {
    [ClusterKey.Compensation]: 'SALARY',
    [ClusterKey.Company]: 'EMPLOYER',
};

const getGenericAnalyticsValue = <F extends keyof SearchClusterMap>(
    selectedValues: SelectedValuesTypeByClusterKey<F> | null | undefined
) => {
    if (Array.isArray(selectedValues)) {
        return selectedValues.length ? selectedValues.join(', ') : 'null';
    }
    return selectedValues || 'null';
};

export const sendFilterChangeAnalytics = <F extends keyof SearchClusterMap>(
    filter: F,
    oldSelectedValues: SelectedValuesTypeByClusterKey<F> | null | undefined,
    newSelectedValues: SelectedValuesTypeByClusterKey<F>,
    searchType: SearchType,
    defaultHhtmSource: string,
    isMobile: boolean,
    hhtmSourceLabel?: string
) => {
    // Возможные значения buttonName:
    //   –  vacancy_search_filter_apply     /search/vacancy
    //   –  resume_search_filter_apply      /search/resume
    //   –  vacancy_search_filter_touch     /search/vacancy & XS | S filters
    //   –  resume_search_filter_touch      /search/resume  & XS | S filters
    const buttonName = `${searchType}_search_filter_${isMobile ? 'touch' : 'apply'}`;

    // Возможные значения hhtmSource:
    //   –  vacancy_search_list             /search/vacancy | /vacancies/
    //   –  resume_search_result            /search/resume
    //   –  vacancy_search_filter           /search/vacancy & XS | S filters
    //   –  resume_search_filter            /search/resume  & XS | S filters
    //   –  resumes_catalog                 /resumes/
    const hhtmSource = isMobile ? `${searchType}_search_filter` : defaultHhtmSource;

    const params: Record<string, string> = {
        type: FILTER_ANALYTICS_LABELS[filter] || filter.toUpperCase(),
        oldValue: JSON.stringify(getGenericAnalyticsValue(oldSelectedValues)),
        newValue: JSON.stringify(getGenericAnalyticsValue(newSelectedValues)),
        hhtmSource,
    };
    if (hhtmSourceLabel) {
        params.hhtmSourceLabel = hhtmSourceLabel;
    }
    Analytics.sendHHEventButtonClick(buttonName, params);
};
