import { FC, useCallback, useMemo } from 'react';

import { Checkbox } from '@hh.ru/magritte-ui';

import FilterItem from 'src/components/Search/Common/Filters/FilterItem';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { toggleValueInArray } from 'src/components/Search/Common/Filters/utils/toggleValueInArray';
import SwitchItem from 'src/components/Search/Vacancy/Filters/FilterSwitchItem';
import { useSelector } from 'src/hooks/useSelector';
import { ClusterKey, SearchVacancyLabelCluster } from 'src/types/search/common/clusters';
import { LabelOption } from 'src/types/search/vacancy/criteria';

const EMPTY_ARRAY: Array<LabelOption> = [];

const NightShifts: FC = () => {
    const labelCluster = useSelector((state) => state.searchClusters[ClusterKey.Label]) as
        | SearchVacancyLabelCluster
        | undefined;
    const nightShiftsGroup = labelCluster?.groups[LabelOption.NightShifts];
    const selectedValues = labelCluster?.selectedValues || EMPTY_ARRAY;
    const isNightShiftsChecked = useMemo(() => selectedValues.includes(LabelOption.NightShifts), [selectedValues]);

    const applyFilter = useApplyFilter(ClusterKey.Label);
    const toggleNightShifts = useCallback(
        () => applyFilter(toggleValueInArray(LabelOption.NightShifts, selectedValues)),
        [applyFilter, selectedValues]
    );

    if (!nightShiftsGroup) {
        return null;
    }

    return (
        <MobileViewControl
            mobileView={
                <SwitchItem
                    title={nightShiftsGroup.title}
                    checked={isNightShiftsChecked}
                    onClick={toggleNightShifts}
                    dataQa={`serp__novafilter-${LabelOption.Internship}-${nightShiftsGroup.id}`}
                    withoutSpacing
                />
            }
        >
            <FilterItem
                left={
                    <Checkbox
                        name={ClusterKey.Label}
                        value={nightShiftsGroup.id}
                        dataQaCheckbox={`serp__novafilter-${LabelOption.Internship}-${nightShiftsGroup.id}`}
                        onChange={toggleNightShifts}
                        checked={isNightShiftsChecked}
                    />
                }
                title={nightShiftsGroup.title}
                disabled={nightShiftsGroup.disabled}
                count={nightShiftsGroup.count}
            />
        </MobileViewControl>
    );
};

export default NightShifts;
