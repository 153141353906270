import { useCallback } from 'react';

import { ClusterKey, SelectedValuesTypeByClusterKey } from 'src/types/search/common/clusters';

import { useApplyFilters } from 'src/components/Search/Common/Filters/hooks/useApplyFilters';
import { useUpdateFilter } from 'src/components/Search/Common/Filters/hooks/useUpdateFilter';

type UseApplyFilterHook = <F extends ClusterKey>(
    filter: F
) => (newSelectedValues: SelectedValuesTypeByClusterKey<F>) => void;

export const useApplyFilter: UseApplyFilterHook = (filter) => {
    const updateFilter = useUpdateFilter(filter);
    const applyFilters = useApplyFilters();

    return useCallback(
        (newSelectedValues) => {
            updateFilter(newSelectedValues);
            applyFilters();
        },
        [applyFilters, updateFilter]
    );
};
