import { ClusterGroup } from 'src/types/search/common/clusters';

export const sortMetroByTitleDesc = (a: ClusterGroup, b: ClusterGroup): number => {
    if (a.title > b.title) {
        return 1;
    }
    if (b.title > a.title) {
        return -1;
    }
    return 0;
};
