import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterContent from 'src/components/Search/Common/Filters/FilterContent';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useHasClusterGroups } from 'src/components/Search/Common/Filters/hooks/useHasClusterGroups';
import translation from 'src/components/translation';
import { ClusterKey } from 'src/types/search/common/clusters';

import NeighboursAreaMobile from 'src/components/Search/Vacancy/Filters/Neighbours/NeighboursMobile';

const TrlKeys = {
    title: 'clusters.neighbours',
};

const Neighbours: TranslatedComponent = ({ trls }) => {
    const hasCluster = useHasClusterGroups(ClusterKey.Neighbours);
    if (!hasCluster) {
        return null;
    }

    return (
        <MobileViewControl mobileView={<NeighboursAreaMobile title={trls[TrlKeys.title]} />}>
            <FilterWrapper filterKey={ClusterKey.Neighbours} title={trls[TrlKeys.title]}>
                <FilterContent name={ClusterKey.Neighbours} />
            </FilterWrapper>
        </MobileViewControl>
    );
};

export default translation(Neighbours);
