import { useRef, useEffect, RefObject, FC, useContext } from 'react';
import classnames from 'classnames';

import { Tooltip } from '@hh.ru/magritte-ui';

import { SearchFilterTipContext } from 'src/components/Search/Common/Filters/TipContext';
import TotalResumesFound from 'src/components/Search/Resume/Filters/TotalResumesFound';
import TotalVacanciesFound from 'src/components/VacancySearchHeader/components/TotalVacanciesFound';
import { useSearchSettings } from 'src/hooks/search/useSearchSettings';
import { useSelector } from 'src/hooks/useSelector';
import { ClusterKey } from 'src/types/search/common/clusters';

import styles from './styles.less';

const TIP_POSITION_DEFAULT_HEIGHT = 20;

const isTipInViewport = (tip: HTMLDivElement) => {
    const rect = tip.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

// для связанных фильтров (куда переместить FilterTip)
const FILTER_MAP: Partial<Record<ClusterKey, ClusterKey>> = {
    [ClusterKey.Neighbours]: ClusterKey.Area,
    [ClusterKey.FilterExpPeriodForIndustry]: ClusterKey.FilterExpIndustry,
};

interface FilterTipProps {
    eventElRef: RefObject<HTMLDivElement>;
    formRef: RefObject<HTMLDivElement>;
    setEventRef: (element: HTMLDivElement) => void;
}

const FilterTip: FC<FilterTipProps> = ({ eventElRef, formRef, setEventRef }) => {
    const filtersRefs = useContext(SearchFilterTipContext);
    const tipRef = useRef<HTMLInputElement>(null);
    const defaultRef = useRef<HTMLInputElement>(null);
    const { show, totalResults, filter } = useSelector((state) => state.searchClustersTip);
    const alternativePosition = useSelector((state) => state.searchClustersTipAlternativePosition);
    const { isVacancySearchType } = useSearchSettings();

    useEffect(() => {
        if (!formRef.current || !tipRef.current || !defaultRef.current) {
            return;
        }
        if (show) {
            const parentPosition = formRef.current.getBoundingClientRect();
            let lastEventPosition = eventElRef?.current?.getBoundingClientRect();
            let tipPlaceByFilterName;
            // for hideable filters (after re-renders)
            if (!lastEventPosition || lastEventPosition.top === 0) {
                if (filter) {
                    const currentFilter = FILTER_MAP[filter] || filter;
                    tipPlaceByFilterName = filtersRefs.current?.[currentFilter];
                }
                lastEventPosition = (tipPlaceByFilterName || defaultRef.current).getBoundingClientRect();
            }
            // update tip position
            tipRef.current.style.top = `${lastEventPosition.top - parentPosition.top}px`;
            tipRef.current.style.height = `${
                tipPlaceByFilterName ? TIP_POSITION_DEFAULT_HEIGHT : lastEventPosition.height
            }px`;
            tipPlaceByFilterName && setEventRef(tipRef.current);
            if (!isTipInViewport(tipRef.current)) {
                tipRef.current.scrollIntoView({
                    block: 'center',
                });
            }
        }
    }, [eventElRef, filter, filtersRefs, formRef, setEventRef, show]);

    return (
        <>
            <Tooltip
                placement={`${alternativePosition ? 'left' : 'right'}-center`}
                visible={show}
                activatorRef={tipRef}
            >
                <div data-qa="search-filters-tooltip">
                    {isVacancySearchType ? (
                        <TotalVacanciesFound count={totalResults} />
                    ) : (
                        <TotalResumesFound count={totalResults} />
                    )}
                </div>
            </Tooltip>
            <div
                ref={tipRef}
                className={classnames(styles.tip, { [styles.tipAlternativePosition]: alternativePosition })}
            />
            <div ref={defaultRef} />
        </>
    );
};

export default FilterTip;
