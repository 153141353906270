import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AdaptiveViewFilter from 'src/components/Search/Vacancy/Filters/AdaptiveViewFilter';
import translation from 'src/components/translation';
import { ClusterKey } from 'src/types/search/common/clusters';

const TrlKeys = {
    title: 'vacancySearch.keywords',
};

const SearchField: TranslatedComponent = ({ trls }) => {
    return <AdaptiveViewFilter title={trls[TrlKeys.title]} name={ClusterKey.SearchField} />;
};

export default translation(SearchField);
