import { useCallback, useMemo, useState } from 'react';

import { FormLabel, fromTree, Link, VSpacing } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterTreeSelector from 'src/components/Search/Common/Filters/TreeSelector';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ClusterKey } from 'src/types/search/common/clusters';

import SelectedRoles from 'src/components/Search/Vacancy/Filters/ProfessionalRole/Mobile/SelectedRoles';

const EMPTY_ARRAY: Array<string> = [];

const TrlKeys = {
    title: 'search.filters.professional_role',
    add: 'novaFilters.professionalRoles.add',
};

const ProfessionalRolesMobile: TranslatedComponent = ({ trls }) => {
    const professionalRoleTree = useSelector(({ professionalRoleTree }) => professionalRoleTree);
    const selectedValuesRaw =
        useSelector((state) => state.searchClusters[ClusterKey.ProfessionalRole].selectedValues) || EMPTY_ARRAY;

    const collection = useMemo(() => fromTree(professionalRoleTree.items), [professionalRoleTree]);
    const selectedValues = useMemo(() => selectedValuesRaw.map(String), [selectedValuesRaw]);
    const [isVisible, setVisible] = useState<boolean>(false);

    const applyFilter = useApplyFilter(ClusterKey.ProfessionalRole);

    const handleClear = useCallback(() => {
        applyFilter([]);
    }, [applyFilter]);

    return (
        <>
            <FormLabel>{trls[TrlKeys.title]}</FormLabel>
            <VSpacing default={12} />
            <SelectedRoles selectedValues={selectedValues} collection={collection} onClear={handleClear} />
            <VSpacing default={12} />
            <Link
                iconLeft={<PlusOutlinedSize16 />}
                Element="button"
                data-qa="novafilters-mobile-add-professional-roles"
                onClick={() => setVisible(true)}
            >
                {trls[TrlKeys.add]}
            </Link>
            <VSpacing default={24} />
            <FilterTreeSelector
                title={trls[TrlKeys.title]}
                isVisible={isVisible}
                setVisible={setVisible}
                collection={collection}
                applyFilter={(values) => applyFilter(values.map(Number))}
                selectedValues={selectedValues}
                shouldSelectChildValuesOnly
            />
        </>
    );
};

export default translation(ProfessionalRolesMobile);
