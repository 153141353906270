import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useBreakpoint } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { sendFilterChangeAnalytics } from 'src/components/Search/Common/Filters/utils/sendFilterChangeAnalytics';
import { useSearchSettings } from 'src/hooks/search/useSearchSettings';
import { useSelector } from 'src/hooks/useSelector';
import { updateSearchCluster } from 'src/models/search/common/clusters';
import { ClusterKey, SelectedValuesTypeByClusterKey } from 'src/types/search/common/clusters';

const lastUpdatedSearchClusterAction = makeSetStoreField('lastUpdatedSearchCluster');

type UseUpdateFilterHook = <F extends ClusterKey>(
    filter: F
) => (newSelectedValues: SelectedValuesTypeByClusterKey<F>) => void;

export const useUpdateFilter: UseUpdateFilterHook = (filter) => {
    const dispatch = useDispatch();

    const { searchType } = useSearchSettings();
    const oldSelectedValues = useSelector((state) => state.searchClusters[filter]?.selectedValues);
    const defaultHhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);
    const { isMobile } = useBreakpoint();

    return useCallback(
        (newSelectedValues) => {
            sendFilterChangeAnalytics(
                filter,
                oldSelectedValues,
                newSelectedValues,
                searchType,
                defaultHhtmSource,
                isMobile
            );

            dispatch([
                updateSearchCluster({ filter, data: newSelectedValues }),
                lastUpdatedSearchClusterAction(filter),
            ]);
        },
        [filter, oldSelectedValues, searchType, defaultHhtmSource, isMobile, dispatch]
    );
};
