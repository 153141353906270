import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterContent from 'src/components/Search/Common/Filters/FilterContent';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import AcceptTemporary from 'src/components/Search/Vacancy/Filters/AcceptTemporary';
import Internship from 'src/components/Search/Vacancy/Filters/Internship';
import MobileFilter from 'src/components/Search/Vacancy/Filters/MobileFilter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ClusterKey, SearchVacancyLabelCluster } from 'src/types/search/common/clusters';
import { LabelOption } from 'src/types/search/vacancy/criteria';

import styles from './styles.less';

const TrlKeys = {
    title: 'searchvacancy.clusters.employment',
};

const Employment: TranslatedComponent = ({ trls }) => {
    const acceptTemporaryCluster = useSelector((state) => state.searchClusters[ClusterKey.AcceptTemporary]);
    const labelCluster = useSelector((state) => state.searchClusters[ClusterKey.Label]) as
        | SearchVacancyLabelCluster
        | undefined;
    const internshipGroup = labelCluster?.groups?.[LabelOption.Internship];
    const isAdditionalFiltersVisible = Boolean(acceptTemporaryCluster) || Boolean(internshipGroup);

    return (
        <>
            <MobileViewControl
                mobileView={
                    <MobileFilter
                        name={ClusterKey.Employment}
                        title={trls[TrlKeys.title]}
                        withDivider={Boolean(acceptTemporaryCluster)}
                    />
                }
            >
                <FilterWrapper
                    filterKey={ClusterKey.Employment}
                    title={trls[TrlKeys.title]}
                    withDivider={isAdditionalFiltersVisible}
                >
                    <FilterContent name={ClusterKey.Employment} />
                </FilterWrapper>
            </MobileViewControl>
            {isAdditionalFiltersVisible && (
                <div className={styles.list}>
                    <AcceptTemporary />
                    <Internship />
                </div>
            )}
        </>
    );
};

export default translation(Employment);
