import { FC, createContext, MutableRefObject, PropsWithChildren, useRef } from 'react';

import { ClusterKey } from 'src/types/search/common/clusters';

type FiltersRefs = Partial<Record<ClusterKey, HTMLDivElement | null>>;

export const SearchFilterTipContext = createContext<MutableRefObject<FiltersRefs>>({ current: {} });

const SearchFilterTipContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const filtersRefs = useRef<FiltersRefs>({});

    return <SearchFilterTipContext.Provider value={filtersRefs}>{children}</SearchFilterTipContext.Provider>;
};

export default SearchFilterTipContextProvider;
