export const isCheckedOrIndeterminate = (
    id: string,
    checkedValues: string[]
): { checked: boolean; indeterminate: boolean } => {
    const checked = checkedValues.includes(id);
    const indeterminate =
        !checked &&
        checkedValues.some((item) => {
            const [parent] = item.split('.');
            return parent === id && item.includes('.');
        });
    return {
        checked,
        indeterminate,
    };
};
