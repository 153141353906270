import { FormLabel, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useApplySort } from 'src/components/Search/Common/Filters/hooks/useApplySort';
import ChipsSelect from 'src/components/Search/Vacancy/Filters/FilterChips/Select';
import { ChipsSelectType } from 'src/components/Search/Vacancy/Filters/FilterChips/types';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { OrderByOption } from 'src/types/search/common/criteria';

const TrlKeys = {
    title: 'search.vacancy.clusters.sort.title',
    options: {
        [OrderByOption.PublicationTime]: 'vacancySearch.sortCode.publication_time',
        [OrderByOption.SalaryDesc]: 'vacancySearch.sortCode.salary_desc',
        [OrderByOption.SalaryAsc]: 'vacancySearch.sortCode.salary_asc',
        [OrderByOption.Relevance]: 'vacancySearch.sortCode.relevance',
    },
};

const Order: TranslatedComponent = ({ trls }) => {
    const value = useSelector((state) => state.novaSorts.orderBy);
    const options = useSelector((state) => state.vacancySearchDictionaries.orderBy);
    const applySort = useApplySort('orderBy');

    if (!options) {
        return null;
    }

    return (
        <>
            <FormLabel>{trls[TrlKeys.title]}</FormLabel>
            <VSpacing default={12} />
            <ChipsSelect
                name="order_by"
                selected={[value]}
                data-qa="serp-settings__order-by-menu"
                options={options.map((option) => ({
                    id: option,
                    title: trls[TrlKeys.options[option]],
                }))}
                selectType={ChipsSelectType.Radio}
                onChange={(values) => applySort(values[0])}
            />
            <VSpacing default={24} />
        </>
    );
};

export default translation(Order);
