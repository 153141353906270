import { useCallback, useMemo, useState } from 'react';

import { Checkbox, fromTree, Link } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterItem from 'src/components/Search/Common/Filters/FilterItem';
import FilterList from 'src/components/Search/Common/Filters/FilterList';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import LinkMore from 'src/components/Search/Common/Filters/LinkMore';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import FilterTreeSelector from 'src/components/Search/Common/Filters/TreeSelector';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useFilterGroups } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import { toggleValueInArray } from 'src/components/Search/Common/Filters/utils/toggleValueInArray';
import translation from 'src/components/translation';
import { useSearchSettings } from 'src/hooks/search/useSearchSettings';
import { useSelector } from 'src/hooks/useSelector';
import { ClusterGroup, ClusterKey } from 'src/types/search/common/clusters';
import { PROFESSIONAL_ROLE_OTHER_ID } from 'src/utils/constants/professionalRoles';

import MobileFilter from 'src/components/Search/Vacancy/Filters/ProfessionalRole/Mobile';

const EMPTY_ARRAY: Array<string> = [];

const sortOtherComparator = (a: ClusterGroup, b: ClusterGroup): number => {
    if (a.id === PROFESSIONAL_ROLE_OTHER_ID) {
        return 1;
    }
    if (b.id === PROFESSIONAL_ROLE_OTHER_ID) {
        return -1;
    }
    return 0;
};

const TrlKeys = {
    title: 'search.filters.professional_role',
    selectMoreTrigger: 'clusters.chooseMore',
};

const ProfessionalRole: TranslatedComponent = ({ trls }) => {
    const { isEmployerViewPage } = useSearchSettings();

    const applyFilter = useApplyFilter(ClusterKey.ProfessionalRole);
    const groups = useSelector((state) => state.searchClusters[ClusterKey.ProfessionalRole].groups);
    const selectedValuesRaw =
        useSelector((state) => state.searchClusters[ClusterKey.ProfessionalRole].selectedValues) || EMPTY_ARRAY;
    const selectedValues = useMemo(() => selectedValuesRaw.map(String), [selectedValuesRaw]);
    const orders = useSelector((state) => state.searchClustersOrder?.[ClusterKey.ProfessionalRole]) || EMPTY_ARRAY;
    const { items, breakpoint } = useFilterGroups(groups, selectedValues.length, orders);

    const filteredItems = useMemo(() => {
        const filteredItems = items.slice(0, breakpoint);
        if (!selectedValues.includes(PROFESSIONAL_ROLE_OTHER_ID)) {
            filteredItems.sort(sortOtherComparator);
        }
        return filteredItems;
    }, [breakpoint, selectedValues, items]);

    const handleChangeFilter = useCallback(
        (value: string) => applyFilter(toggleValueInArray(value, selectedValues).map(Number)),
        [applyFilter, selectedValues]
    );

    const treeItems = useSelector((state) => state.professionalRoleTree.items);
    const collection = useMemo(() => fromTree(treeItems), [treeItems]);
    const [isVisible, setVisible] = useState(false);

    const counts = useMemo(() => {
        const result: Record<string, number> = {};
        Object.values(groups).forEach(({ id, count }) => {
            result[id] = count;
        });
        const parents = collection.getTopLevel();
        parents.forEach(({ id }) => {
            result[id] = collection.getChildrenIds(id).reduce((acc, childId) => acc + (result[childId] || 0), 0);
        });
        return result;
    }, [collection, groups]);

    return (
        <MobileViewControl mobileView={<MobileFilter />}>
            <FilterWrapper filterKey={ClusterKey.ProfessionalRole} title={trls[TrlKeys.title]}>
                <FilterList>
                    {filteredItems.map(({ title, id }) => {
                        const group = groups[id];
                        return (
                            <FilterItem
                                key={id}
                                left={
                                    <Checkbox
                                        value={id}
                                        onChange={({ target }) => handleChangeFilter(target.value)}
                                        checked={selectedValues.includes(id)}
                                        disabled={!group}
                                    />
                                }
                                title={title}
                                count={group.count}
                                disabled={!group}
                            />
                        );
                    })}
                </FilterList>
                {!isEmployerViewPage && (
                    <>
                        <LinkMore onClick={() => setVisible(true)}>
                            <Link>{trls[TrlKeys.selectMoreTrigger]}</Link>
                        </LinkMore>
                        <FilterTreeSelector
                            title={trls[TrlKeys.title]}
                            isVisible={isVisible}
                            setVisible={setVisible}
                            collection={collection}
                            applyFilter={(values) => applyFilter(values.map(Number))}
                            selectedValues={selectedValues}
                            counts={counts}
                            shouldSelectChildValuesOnly
                        />
                    </>
                )}
            </FilterWrapper>
        </MobileViewControl>
    );
};

export default translation(ProfessionalRole);
