import { useCallback, useEffect, useMemo, useState } from 'react';

import { VSpacingContainer, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useDebouncedCountsRequestMobile } from 'src/components/Search/Common/Filters/hooks/useSendFilterForm';
import { isMetroCheckedOrIndeterminate } from 'src/components/Search/Common/Filters/utils';
import ChipsList from 'src/components/Search/Vacancy/Filters/FilterChips';
import ChipsSelect from 'src/components/Search/Vacancy/Filters/FilterChips/Select';
import { ChipsSelectType } from 'src/components/Search/Vacancy/Filters/FilterChips/types';
import MobileListItem from 'src/components/Search/Vacancy/Filters/FilterMobileListItem';
import LineIcon from 'src/components/Search/Vacancy/Filters/Metro/LineIcon';
import PointIcon from 'src/components/Search/Vacancy/Filters/Metro/PointIcon';
import useSearchMetro from 'src/components/Search/Vacancy/Filters/Metro/hooks/useSearchMetro';
import { sortMetroByTitleDesc } from 'src/components/Search/Vacancy/Filters/Metro/utils/sort';
import MobileFilterWithActivator from 'src/components/Search/Vacancy/Filters/MobileFilterWithActivator';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EMPTY_CLUSTER } from 'src/models/search/common/clusters';
import {
    MetroType,
    ClusterKey,
    SearchVacancyMetroCluster,
    SearchVacancyMetroClusterGroup,
    SearchVacancyMetroClusterGroupMap,
} from 'src/types/search/common/clusters';

const sortMetroGroupsByTitleDesc = (groups: SearchVacancyMetroClusterGroupMap) =>
    Object.values(groups).sort(sortMetroByTitleDesc);

const TrlKeys = {
    add: 'vacancySearch.metro.add',
    title: 'searchvacancy.clusters.metro',
    inputPlaceholder: 'search.clusters.input.placeholder',
    [MetroType.Line]: 'vacancySearch.metro.lines',
    [MetroType.Station]: 'vacancySearch.metro.stations',
};

const MetroMobile: TranslatedComponent = ({ trls }) => {
    const applyFilter = useApplyFilter(ClusterKey.Metro);
    const sendCountsRequest = useDebouncedCountsRequestMobile();

    // global state data
    const { groups, selectedValues } = (useSelector((state) => state.searchClusters[ClusterKey.Metro]) ||
        EMPTY_CLUSTER) as SearchVacancyMetroCluster;

    // chips, search list
    const [metroList, setMetroList] = useState<SearchVacancyMetroClusterGroup[]>([]);
    const [metroListByType, setMetroListByType] = useState<SearchVacancyMetroClusterGroup[]>([]);
    const [selectedMetroType, setSelectedMetroType] = useState<MetroType>(MetroType.Station);

    // modal data
    const [showModal, setShowModal] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');

    // need update chips list after spa/ajax requests
    useEffect(() => {
        const list: SearchVacancyMetroClusterGroup[] = sortMetroGroupsByTitleDesc(groups);
        setMetroList(list);
        setMetroListByType(list.filter(({ type }) => type === MetroType.Station));
    }, [groups]);

    // TODO: для addon должен использоваться компонент из магритта https://jira.hh.ru/browse/PORTFOLIO-31375
    const optionsChips = useMemo(() => {
        return metroList
            .filter(({ id }) => selectedValues.includes(id))
            .map(({ id, title, type, color }) => ({
                id,
                title,
                addon: (
                    <>
                        {type === MetroType.Station && <PointIcon color={color} />}
                        {type === MetroType.Line && <LineIcon color={color} />}
                    </>
                ),
            }));
    }, [metroList, selectedValues]);

    const handleClear = useCallback(
        (metro: string) => applyFilter(selectedValues.filter((item) => item !== metro)),
        [applyFilter, selectedValues]
    );

    const { isSearchMetroEnabled, searchMetroList, searchMetroDebounced } = useSearchMetro();

    const handleMetro = useCallback(
        (metro: string, group: SearchVacancyMetroClusterGroup) => {
            let values = [...selectedValues];
            const index = values.indexOf(metro);
            if (index !== -1) {
                values.splice(index, 1);
            } else {
                // if use line filter
                // delete all station for selected line
                if (group.type === MetroType.Line) {
                    values = values.filter((item) => item.split('.')[0] !== group.lineId);
                }
                // if use station filter - delete line
                if (group.type === MetroType.Station) {
                    const [line] = metro.split('.');
                    values = values.filter((item) => item !== line);
                }
                values.push(metro);
            }
            applyFilter(values);

            const haveSelectedGroup = metroList.filter(({ id }) => id === metro).length > 0;
            if (haveSelectedGroup) {
                return;
            }

            const searchMetro = searchMetroList.find(({ id }) => id === metro);
            if (searchMetro) {
                const newList = [searchMetro, ...metroList].sort(sortMetroByTitleDesc);
                setMetroList(newList);
                setMetroListByType(newList.filter(({ type }) => type === MetroType.Station));
            }
        },
        [applyFilter, metroList, searchMetroList, selectedValues]
    );

    const filterMetro = useCallback(
        (metroType: MetroType) => setMetroListByType(metroList.filter(({ type }) => type === metroType)),
        [metroList]
    );

    const onChangeSearchQuery = useCallback(
        (value: string) => {
            setSearchQuery(value);
            searchMetroDebounced(value);
        },
        [searchMetroDebounced]
    );

    const onCloseOrBack = useCallback(() => {
        setShowModal(false);
        onChangeSearchQuery('');
        setSelectedMetroType(MetroType.Station);
        filterMetro(MetroType.Station);
        sendCountsRequest();
    }, [onChangeSearchQuery, filterMetro, sendCountsRequest]);

    // для метро должен использоваться selection bottom sheet https://jira.hh.ru/browse/PORTFOLIO-31376
    return (
        <MobileFilterWithActivator
            title={trls[TrlKeys.title]}
            titleModal={trls[TrlKeys.title]}
            add={trls[TrlKeys.add]}
            showModal={showModal}
            setShowModal={setShowModal}
            selected={<ChipsList name={ClusterKey.Metro} options={optionsChips} onClear={(id) => handleClear(id)} />}
            onChangeSearchQuery={onChangeSearchQuery}
            searchQuery={searchQuery}
            onBack={onCloseOrBack}
            onClose={onCloseOrBack}
            additionalOptions={
                <>
                    {!isSearchMetroEnabled && (
                        <>
                            <VSpacing default={12} />
                            <ChipsSelect
                                name="metro_type"
                                selected={[selectedMetroType]}
                                data-qa="serp-settings__metro-type"
                                options={Object.values(MetroType).map((type) => ({
                                    id: type,
                                    title: trls[TrlKeys[type]],
                                }))}
                                selectType={ChipsSelectType.Radio}
                                onChange={(values) => {
                                    const metroType = values[0];
                                    setSelectedMetroType(metroType);
                                    filterMetro(metroType);
                                }}
                            />
                        </>
                    )}
                </>
            }
            content={
                <VSpacingContainer default={12}>
                    {(isSearchMetroEnabled ? searchMetroList : metroListByType).map((item) => {
                        const { id, color, type, ...metro } = item;
                        const { checked, indeterminate } = isMetroCheckedOrIndeterminate(
                            id,
                            selectedValues,
                            color,
                            type
                        );
                        return (
                            <MobileListItem
                                item={{ id, title: metro.title, count: 0 }}
                                key={id}
                                name={ClusterKey.Metro}
                                checked={checked}
                                indeterminate={indeterminate}
                                onChange={(id) => handleMetro(id, item)}
                                left={
                                    <span>
                                        {type === MetroType.Station && <PointIcon color={color} />}
                                        {type === MetroType.Line && <LineIcon color={color} />}
                                    </span>
                                }
                            />
                        );
                    })}
                </VSpacingContainer>
            }
            activatorDataQa="novafilters-mobile-add-metro"
        />
    );
};

export default translation(MetroMobile);
