import { useState, useMemo, useCallback, useEffect, FC, ReactNode } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';
import { DataProvider, RemoteDataProviderResponse } from 'bloko/blocks/suggest/types';
import debounce from 'bloko/common/debounce';

import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useDebouncedCountsRequestMobile } from 'src/components/Search/Common/Filters/hooks/useSendFilterForm';
import { toggleValueInArray } from 'src/components/Search/Common/Filters/utils/toggleValueInArray';
import ChipsList from 'src/components/Search/Vacancy/Filters/FilterChips';
import MobileListItem from 'src/components/Search/Vacancy/Filters/FilterMobileListItem';
import MobileFilterWithActivator from 'src/components/Search/Vacancy/Filters/MobileFilterWithActivator';
import { useSelector } from 'src/hooks/useSelector';
import { EMPTY_CLUSTER } from 'src/models/search/common/clusters';
import { ClusterKey, ClusterGroup } from 'src/types/search/common/clusters';

interface SearchItem {
    id: string;
    title: string;
}

interface MobileListProps {
    filterName: typeof ClusterKey.Area | typeof ClusterKey.District;
    dataProvider: DataProvider;
    title: ReactNode;
    add: string;
}

const MobileList: FC<MobileListProps> = ({ filterName, dataProvider, title, add }) => {
    const applyFilter = useApplyFilter(filterName);
    const sendCountsRequest = useDebouncedCountsRequestMobile();

    // global state data
    const { groups, selectedValues } = useSelector((state) => state.searchClusters?.[filterName]) || EMPTY_CLUSTER;

    // items
    const [items, setItems] = useState<ClusterGroup[]>(Object.values(groups));

    // need update items after spa/ajax requests
    useEffect(() => setItems(Object.values(groups)), [groups]);

    // modal data
    const [showModal, setShowModal] = useState<boolean>(false);
    const [searchQuery, setQuery] = useState<string>('');
    const [enableSearch, setEnableSearch] = useState(false);
    const [searchItems, setSearchItems] = useState<SearchItem[]>([]);

    const chipsOptions = useMemo(
        () => items.filter(({ id }) => selectedValues.includes(Number(id))),
        [items, selectedValues]
    );

    const onChange = useCallback(
        (value: string) => {
            // update items states
            applyFilter(toggleValueInArray(Number(value), selectedValues));

            // for suggest items need add update chips items
            const haveSelectedGroup = items.some(({ id }) => id === value);
            if (haveSelectedGroup) {
                return;
            }
            const searchItem = searchItems.find(({ id }) => id === value);
            if (searchItem) {
                setItems(items.concat({ ...searchItem, count: 0, order: 0 }));
            }
        },
        [selectedValues, applyFilter, items, searchItems]
    );

    // suggest
    const searchDebounced = useMemo(
        () =>
            debounce(async (searchText: string) => {
                let results: RemoteDataProviderResponse = { items: [] };
                try {
                    results = await dataProvider(searchText.trim().toUpperCase());
                } catch (error) {
                    console.error(error);
                } finally {
                    if (results.items) {
                        setSearchItems(results.items.map((item) => ({ title: item.text, id: item.id || '' })));
                        setEnableSearch(searchText.length > 0);
                    }
                }
            }, 400),
        [dataProvider]
    );

    const onChangeSearchQuery = useCallback(
        (value: string) => {
            setQuery(value);
            searchDebounced(value);
        },
        [searchDebounced]
    );

    const onCloseOrBack = useCallback(() => {
        setShowModal(false);
        sendCountsRequest();
        setQuery('');
        setEnableSearch(false);
    }, [sendCountsRequest]);

    // для подобных фильтров должен использоваться selection bottom sheet https://jira.hh.ru/browse/PORTFOLIO-31376
    return (
        <>
            <MobileFilterWithActivator
                title={title}
                titleModal={title}
                add={add}
                showModal={showModal}
                setShowModal={setShowModal}
                selected={
                    <ChipsList
                        name={filterName}
                        options={chipsOptions}
                        onClear={(id) => {
                            onChange(id);
                            sendCountsRequest();
                        }}
                    />
                }
                onChangeSearchQuery={onChangeSearchQuery}
                searchQuery={searchQuery}
                onBack={onCloseOrBack}
                onClose={onCloseOrBack}
                content={
                    <VSpacingContainer default={12}>
                        {!enableSearch &&
                            showModal &&
                            items.map((item) => (
                                <MobileListItem
                                    key={item.id}
                                    name={filterName}
                                    item={item}
                                    onChange={onChange}
                                    checked={selectedValues.includes(Number(item.id))}
                                />
                            ))}
                        {enableSearch &&
                            searchItems.map(({ id, title }) => (
                                <MobileListItem
                                    key={id}
                                    name={filterName}
                                    item={{ title, id, count: 0, order: 0 }}
                                    onChange={onChange}
                                    checked={selectedValues.includes(Number(id))}
                                />
                            ))}
                    </VSpacingContainer>
                }
                containerDataQa={`novafilters-mobile-container-${filterName}`}
                activatorDataQa={`novafilters-mobile-add-${filterName}s`}
                inputDataQa={`novafilters-mobile-${filterName}-input`}
            />
        </>
    );
};

export default MobileList;
