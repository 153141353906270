import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import getSearchParams from 'src/components/Search/Common/Filters/utils/getSearchParams';
import { EmployerVacancySearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/employerVacancy';
import { ResumeSearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/resume';
import { VacancySearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/vacancy';
import { VacancyMapSearchParams } from 'src/components/Search/Common/Filters/utils/getSearchParams/vacancyMap';
import { useSearchSettings } from 'src/hooks/search/useSearchSettings';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/types/search/common/criteria';

export const useSearchParams = ():
    | VacancySearchParams
    | ResumeSearchParams
    | VacancyMapSearchParams
    | EmployerVacancySearchParams => {
    const dispatch = useDispatch();
    const criteriaCurrencyCode = useSelector((state) => state.criteriaCurrencyCode);
    const criteriaTextUpdated = useSelector((state) => state.criteriaTextUpdated);
    const currencies = useSelector((state) => state.currencies);
    const employerInfo = useSelector((state) => state.employerInfo);
    const employerVacancySearch = useSelector((state) => state.employerVacancySearch);
    const employerVacancyTemplateFilter = useSelector((state) => state.employerVacancyTemplateFilter);
    const enableVacancySnippets = useSelector((state) => state.enableVacancySnippets);
    const hasChameleon = useSelector((state) => state.hasChameleon);
    const isAdjustDetectedRegionResumeExpEnabled = useSelector((state) => state.isAdjustDetectedRegionResumeExpEnabled);
    const isExpNewEmploymentFilters = useSelector((state) => state.isExpNewEmploymentFilters);
    const novaSorts = useSelector((state) => state.novaSorts);
    const resumeSearchResult = useSelector((state) => state.resumeSearchResult);
    const router = useSelector((state) => state.router);
    const searchCatalogRedirect = useSelector((state) => state.searchCatalogRedirect);
    const searchClusters = useSelector((state) => state.searchClusters);
    const searchSessionId = useSelector((state) => state.searchSessionId);
    const vacancySearchResult = useSelector((state) => state.vacancySearchResult);
    const { searchType, searchPage, isEmployerViewPage } = useSearchSettings();

    return useMemo(
        () =>
            getSearchParams({
                dispatch,
                criteriaCurrencyCode,
                criteriaTextUpdated: isEmployerViewPage
                    ? employerVacancySearch.criteria?.[CriteriaKey.Text] || ''
                    : criteriaTextUpdated,
                currencies,
                enableVacancySnippets,
                router,
                novaSorts,
                resumeSearchResult,
                searchCatalogRedirect,
                searchClusters,
                searchType,
                searchPage,
                searchSessionId,
                vacancySearchResult,
                employerVacancySearch,
                employerInfo,
                isAdjustDetectedRegionResumeExpEnabled,
                isExpNewEmploymentFilters,
                employerVacancyTemplateFilter,
                hasChameleon,
            }),
        [
            criteriaCurrencyCode,
            criteriaTextUpdated,
            currencies,
            dispatch,
            employerInfo,
            employerVacancySearch,
            employerVacancyTemplateFilter,
            enableVacancySnippets,
            hasChameleon,
            isAdjustDetectedRegionResumeExpEnabled,
            isEmployerViewPage,
            isExpNewEmploymentFilters,
            novaSorts,
            resumeSearchResult,
            router,
            searchCatalogRedirect,
            searchClusters,
            searchPage,
            searchSessionId,
            searchType,
            vacancySearchResult,
        ]
    );
};
