import { ReactNode, useCallback } from 'react';

import { Link, Loader } from '@hh.ru/magritte-ui';
import { DataProvider } from 'bloko/blocks/suggest/types';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterItemWithCheckbox from 'src/components/Search/Common/Filters/FilterItemWithCheckbox';
import FilterList from 'src/components/Search/Common/Filters/FilterList';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import LinkMore from 'src/components/Search/Common/Filters/LinkMore';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useFilterGroups } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import { hasClusterGroups } from 'src/components/Search/Common/Filters/utils/hasClusterGroups';
import { toggleValueInArray } from 'src/components/Search/Common/Filters/utils/toggleValueInArray';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EMPTY_CLUSTER } from 'src/models/search/common/clusters';
import { ClusterKey } from 'src/types/search/common/clusters';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import AdditionalList from 'src/components/Search/Vacancy/Filters/FilterWithAdditionalList/AdditionalList';
import MobileList from 'src/components/Search/Vacancy/Filters/FilterWithAdditionalList/MobileList';
import { useAdditionalList } from 'src/components/Search/Vacancy/Filters/FilterWithAdditionalList/hooks/useAdditionalList';

const EMPTY_ARRAY = [] as const;

const TrlKeys = {
    hide: 'novafilters.additionalList.hide',
    show: 'novafilters.additionalList.show',
};

interface FilterWithAdditionalListProps {
    filterName: typeof ClusterKey.Area | typeof ClusterKey.District;
    dataProvider: DataProvider;
    title: ReactNode;
    placeholder: string;
    placeholderMobile?: string;
    add: string;
}

const FilterWithAdditionalList: TranslatedComponent<FilterWithAdditionalListProps> = ({
    filterName,
    dataProvider,
    title,
    placeholder,
    add,
    trls,
}) => {
    const applyFilter = useApplyFilter(filterName);

    const cluster = useSelector((state) => state.searchClusters[filterName]) || EMPTY_CLUSTER;
    const { groups, selectedValues } = cluster;
    const orders = useSelector((state) => state.searchClustersOrder[filterName]) || EMPTY_ARRAY;
    const { items, breakpoint } = useFilterGroups(groups, selectedValues.length, orders, false);

    const { isLoading, list, showAdditionalList, toggleShowAdditionalList } = useAdditionalList(filterName);

    const onChange = useCallback(
        (value: string) => applyFilter(toggleValueInArray(Number(value), selectedValues)),
        [applyFilter, selectedValues]
    );

    if (!hasClusterGroups(cluster)) {
        return null;
    }

    return (
        <MobileViewControl
            mobileView={<MobileList filterName={filterName} dataProvider={dataProvider} title={title} add={add} />}
        >
            <FilterWrapper filterKey={filterName} title={title}>
                {showAdditionalList ? (
                    <AdditionalList
                        // showAdditionalList can be true only when list are already loaded
                        allItems={list!}
                        selectedItems={selectedValues}
                        onChange={onChange}
                        placeholder={placeholder}
                        dataProvider={dataProvider}
                        filterName={filterName}
                    />
                ) : (
                    <FilterList>
                        {items.map(
                            (item, index) =>
                                index < breakpoint && (
                                    <FilterItemWithCheckbox
                                        key={item.id}
                                        item={item}
                                        name={filterName}
                                        onChange={onChange}
                                        checked={selectedValues.includes(Number(item.id))}
                                    />
                                )
                        )}
                    </FilterList>
                )}
                {Object.keys(groups).length > 0 && (
                    <LinkMore expanded={showAdditionalList}>
                        <Link Element="button" onClick={toggleShowAdditionalList} inline>
                            {trls[showAdditionalList ? TrlKeys.hide : TrlKeys.show]}
                        </Link>
                        {NON_BREAKING_SPACE}
                        {isLoading && <Loader />}
                    </LinkMore>
                )}
            </FilterWrapper>
        </MobileViewControl>
    );
};

export default translation(FilterWithAdditionalList);
