import { useMemo, FC } from 'react';

import { Divider, FormLabel, VSpacing } from '@hh.ru/magritte-ui';

import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import ChipsSelect from 'src/components/Search/Vacancy/Filters/FilterChips/Select';
import { ChipsSelectType } from 'src/components/Search/Vacancy/Filters/FilterChips/types';
import { useSelector } from 'src/hooks/useSelector';
import { EMPTY_CLUSTER } from 'src/models/search/common/clusters';
import { SearchTypicalClusterMap } from 'src/types/search/common/clusters';

interface MobileFilterProps {
    name: keyof SearchTypicalClusterMap;
    title: string;
    withDivider?: boolean;
}

const MobileFilter: FC<MobileFilterProps> = ({ name, title, withDivider = false }) => {
    const applyFilter = useApplyFilter(name);

    const { groups, selectedValues } = useSelector((state) => state.searchClusters?.[name]) || EMPTY_CLUSTER;
    const groupList = useMemo(() => Object.values(groups), [groups]);

    return (
        <>
            <FormLabel>{title}</FormLabel>
            <VSpacing default={12} />
            <ChipsSelect
                name={name}
                selected={selectedValues?.map(String) || []}
                options={groupList}
                selectType={ChipsSelectType.Checkbox}
                onChange={(values) => applyFilter(values)}
            />
            {withDivider ? <Divider marginTop={12} marginBottom={12} /> : <VSpacing default={24} />}
        </>
    );
};

export default MobileFilter;
