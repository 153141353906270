import { Ref, useMemo } from 'react';

import Analytics, { ElementShownAnchor } from '@hh.ru/analytics-js';
import { CheckableChip, ChipsContainer, FormLabel, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { WorkScheduleByDays as WorkScheduleByDaysType } from '@hh.ru/types-hh-microcore';
import { ElementSpyInstance } from 'bloko/common/elementSpy';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import Infotip from 'src/components/Infotip/MagritteInfotip';
import FilterSelect from 'src/components/Search/Common/Filters/FilterSelect';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useFilterGroups } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EMPTY_CLUSTER } from 'src/models/search/common/clusters';
import { ClusterKey } from 'src/types/search/common/clusters';

const POPULAR_ITEMS_COUNT = 5;
const EMPTY_ARRAY = [] as const;
const INFOTIP_NAME = 'vacancy_search_new_employment_filters';

const filterShownAnalytics = (element: HTMLElement): ElementSpyInstance =>
    Analytics.sendHHEventElementShown(element, {
        name: 'filter_work_schedule_by_days',
    });

const TrlKeys = {
    title: 'searchVacancy.workScheduleByDays.title',
    label: 'searchVacancy.select.label',
    apply: 'searchVacancy.select.apply',
    reset: 'searchVacancy.select.reset',
    infoTitle: 'searchVacancy.newEmploymentFilters.tooltip.title',
    infoDescription: 'searchVacancy.newEmploymentFilters.tooltip.description',
};

const WorkScheduleByDays: TranslatedComponent = ({ trls }) => {
    const { groups, selectedValues } =
        useSelector((state) => state.searchClusters[ClusterKey.WorkScheduleByDays]) || EMPTY_CLUSTER;
    const orders = useSelector((state) => state.searchClustersOrder[ClusterKey.WorkScheduleByDays]) || EMPTY_ARRAY;

    const { items } = useFilterGroups(groups, selectedValues.length, orders);

    const applyFilter = useApplyFilter(ClusterKey.WorkScheduleByDays);

    const popularItems = useMemo(
        () =>
            items
                ?.slice(0, POPULAR_ITEMS_COUNT)
                .filter(({ id }) => !selectedValues.includes(id))
                .map(({ id, title }) => ({ id, title })),
        [items, selectedValues]
    );

    if (!Object.keys(groups).length) {
        return null;
    }

    return (
        <ElementShownAnchor fn={filterShownAnalytics}>
            <MobileViewControl
                mobileView={
                    <>
                        <VSpacingContainer default={12}>
                            <FormLabel>{trls[TrlKeys.title]}</FormLabel>
                            <FilterSelect
                                name={ClusterKey.WorkScheduleByDays}
                                selectedValues={selectedValues}
                                items={items}
                                onChange={(values) => {
                                    applyFilter(values as WorkScheduleByDaysType[]);
                                }}
                            />
                            {popularItems.length > 0 && (
                                <ChipsContainer scrollable noWrap>
                                    {popularItems.map(({ id, title }) => (
                                        <CheckableChip
                                            type="checkbox"
                                            name={ClusterKey.WorkScheduleByDays}
                                            onChange={() => {
                                                applyFilter([...selectedValues, id]);
                                            }}
                                            value={id}
                                            id={id}
                                            key={id}
                                        >
                                            {title}
                                        </CheckableChip>
                                    ))}
                                </ChipsContainer>
                            )}
                        </VSpacingContainer>
                        <VSpacing default={24} />
                    </>
                }
            >
                <FilterWrapper title={trls[TrlKeys.title]}>
                    <Infotip
                        title={trls[TrlKeys.infoTitle]}
                        placement="right-top"
                        name={INFOTIP_NAME}
                        visible
                        markAsViewedEvent={MarkAsViewedEvent.OnHide}
                        showClose
                        forcePlacement
                        render={({ activatorRef }) => (
                            <>
                                <div ref={activatorRef as Ref<HTMLDivElement>} />
                                <FilterSelect
                                    name={ClusterKey.WorkScheduleByDays}
                                    selectedValues={selectedValues}
                                    items={items}
                                    onChange={(values) => {
                                        applyFilter(values as WorkScheduleByDaysType[]);
                                    }}
                                />
                            </>
                        )}
                        useDefaultHost
                    >
                        {trls[TrlKeys.infoDescription]}
                    </Infotip>
                </FilterWrapper>
            </MobileViewControl>
        </ElementShownAnchor>
    );
};

export default translation(WorkScheduleByDays);
