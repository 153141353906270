import { FC } from 'react';

import { VSpacing, Text, Switch, HSpacing } from '@hh.ru/magritte-ui';

import styles from './styles.less';

interface SwitchItemProps {
    title: string;
    checked: boolean;
    onClick: () => void;
    dataQa?: string;
    withoutSpacing?: boolean;
}

const SwitchItem: FC<SwitchItemProps> = ({ title, checked, onClick, dataQa = '', withoutSpacing }) => {
    return (
        <>
            <div className={styles.switch}>
                <Text>{title}</Text>
                <HSpacing default={12} />
                <div>
                    <Switch onClick={onClick} checked={checked} data-qa={dataQa} />
                </div>
            </div>
            {!withoutSpacing && <VSpacing default={24} />}
        </>
    );
};

export default SwitchItem;
