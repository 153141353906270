import { useCallback, useEffect, useRef, useState } from 'react';

import { Enter } from '@hh.ru/magritte-common-keyboard/keys';
import { Radio, HSpacing, NumberInput } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberValidator, { NumberValidatorError } from 'bloko/common/numberValidator';

import FilterItem from 'src/components/Search/Common/Filters/FilterItem';
import ListItem from 'src/components/Search/Common/Filters/ListItem';
import translation from 'src/components/translation';
import { CompensationClusterSelectedValues, ClusterKey } from 'src/types/search/common/clusters';

import styles from './styles.less';

const TrlKeys = {
    labelFrom: 'employer.resumesSearch.salary.from',
    customSalary: 'vacancySearch.compensation.custom',
    maxExceeded: 'novafilters.maxExceeded',
    [NumberValidatorError.DecimalLength]: 'formatted.numeric.input.error.decimalLength',
    [NumberValidatorError.NotNumber]: 'formatted.numeric.input.error.notNumber',
};

interface CustomCompensationProps {
    isInputEnabled: boolean;
    setInputEnabled: (isEnabled: boolean) => void;
    selectedValueFrom: string;
    onlyWithSalary: boolean;
    applyFilter: (newSelectedValues: CompensationClusterSelectedValues) => void;
}

const CustomCompensation: TranslatedComponent<CustomCompensationProps> = ({
    trls,
    isInputEnabled,
    setInputEnabled,
    selectedValueFrom,
    onlyWithSalary,
    applyFilter,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const inputBlockRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isInputEnabled) {
            inputRef.current?.focus();
        }
    }, [isInputEnabled]);

    useEffect(() => {
        if (!inputBlockRef.current) {
            return;
        }
        // В Firefox задизейбленный инпут не кидает клик, и он не прокидывается выше. Из-за чего не срабатывал onClick
        // Добавляю хак с pointerEvents: none, чтобы клик приходил на div
        inputBlockRef.current.style.pointerEvents = 'none';
    }, []);

    const activateCustomCompensation = useCallback(() => {
        if (!inputBlockRef.current) {
            return;
        }
        inputBlockRef.current.style.pointerEvents = 'auto';
        setInputEnabled(true);
    }, [setInputEnabled]);

    const [userInput, setUserInput] = useState(selectedValueFrom);
    useEffect(() => setUserInput(selectedValueFrom), [selectedValueFrom]);

    const handleInputChange = useCallback((value: string) => {
        const [error] = NumberValidator.validate(value, { groupSeparator: '', decimalLength: 0 });
        if (!error) {
            setUserInput(value);
        }
    }, []);

    const handleFilterApply = useCallback(
        () => selectedValueFrom !== userInput && applyFilter({ salary: Number(userInput), onlyWithSalary }),
        [applyFilter, onlyWithSalary, selectedValueFrom, userInput]
    );

    return (
        <>
            <FilterItem
                left={<Radio onChange={activateCustomCompensation} checked={isInputEnabled} />}
                title={trls[TrlKeys.customSalary]}
            />
            <ListItem>
                <div className={styles.customSalary}>
                    <HSpacing default={36} />
                    <div className={styles.input} onClick={activateCustomCompensation}>
                        <div ref={inputBlockRef}>
                            <NumberInput
                                name={ClusterKey.Salary}
                                ref={inputRef}
                                value={userInput}
                                placeholder={trls[TrlKeys.labelFrom]}
                                onChange={handleInputChange}
                                data-qa="novafilters-custom-compensation"
                                disabled={!isInputEnabled}
                                onBlur={handleFilterApply}
                                onKeyDown={(event) => event.code === Enter.code && handleFilterApply()}
                            />
                        </div>
                    </div>
                </div>
            </ListItem>
        </>
    );
};

export default translation(CustomCompensation);
