import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import numberFormatter from 'bloko/common/numberFormatter';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    resumes: 'search.resume.header.short',
};

const TotalResumesFound: TranslatedComponent<{ count: number }> = ({ count, trls }) => {
    return formatToReactComponent(trls[TrlKeys.resumes], {
        '{0}': numberFormatter.format(String(count), {
            groupSeparator: NON_BREAKING_SPACE,
        }),
    });
};

export default translation(TotalResumesFound);
