import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AdaptiveViewFilter from 'src/components/Search/Vacancy/Filters/AdaptiveViewFilter';
import translation from 'src/components/translation';
import { ClusterKey } from 'src/types/search/common/clusters';

const TrlKeys = {
    title: 'searchvacancy.clusters.schedule',
};

const Schedule: TranslatedComponent = ({ trls }) => {
    return <AdaptiveViewFilter name={ClusterKey.Schedule} title={trls[TrlKeys.title]} />;
};

export default translation(Schedule);
