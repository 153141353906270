import { ClustersSetting } from 'src/components/Search/Common/Filters/utils';
import { useSearchSettings } from 'src/hooks/search/useSearchSettings';
import { useSelector } from 'src/hooks/useSelector';
import { ClusterKey } from 'src/types/search/common/clusters';

export const useResetAllLink = (): string => {
    const { searchActionPath, isVacancySearchMapPage } = useSearchSettings();
    const enableSimilarSavedSearch = useSelector((state) => state.vacancySearchResult.enableSimilarSavedSearch);
    const clusters = useSelector((state) => state.searchClusters);
    const domainAreaId = useSelector((state) => state.locale.domainAreaId);

    if (enableSimilarSavedSearch) {
        const resumeFilter = clusters?.[ClusterKey.Resume];

        if (resumeFilter) {
            const groupKeys = Object.keys(resumeFilter.groups);
            const resumeHash = groupKeys[0] || '';
            return `${searchActionPath}?resume=${resumeHash}&${ClustersSetting.ForceFiltersSaving}=true`;
        }
    }

    if (isVacancySearchMapPage) {
        return `${searchActionPath}?area=${domainAreaId}`;
    }

    return searchActionPath;
};
