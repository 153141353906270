import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterContent from 'src/components/Search/Common/Filters/FilterContent';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { hasClusterGroups } from 'src/components/Search/Common/Filters/utils/hasClusterGroups';
import ChipsItem from 'src/components/Search/Vacancy/Filters/FilterChips/Item';
import TotalFiltersCount from 'src/components/Search/Vacancy/Filters/TotalFiltersCount';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { TypeByClusterKey, ClusterKey } from 'src/types/search/common/clusters';

const getResumeTitle = (cluster: TypeByClusterKey<typeof ClusterKey.Resume>, hash: string): string => {
    if (!hash) {
        return '';
    }
    return cluster.groups?.[hash].title;
};

const TrlKeys = {
    filterName: 'novafilters.similarSavedSearch.filter',
    title: 'vacancySearch.clusters.similar.resume',
};

const SimilarResume: TranslatedComponent = ({ trls, ...prop }) => {
    const applyFilter = useApplyFilter(ClusterKey.Resume);

    const enableSimilarSavedSearch = useSelector((state) => state.vacancySearchResult.enableSimilarSavedSearch);
    const cluster = useSelector((state) => state.searchClusters?.[ClusterKey.Resume]);

    if (!cluster || !hasClusterGroups(cluster)) {
        return null;
    }

    return (
        <MobileViewControl
            mobileView={
                <ChipsItem
                    title={trls[TrlKeys.title]}
                    value={getResumeTitle(cluster, cluster.selectedValues[0])}
                    onDelete={() => applyFilter([])}
                />
            }
        >
            {enableSimilarSavedSearch && <TotalFiltersCount />}
            <FilterWrapper filterKey={ClusterKey.Resume} title={trls[TrlKeys.title]}>
                <FilterContent name={ClusterKey.Resume} {...prop} />
            </FilterWrapper>
        </MobileViewControl>
    );
};

export default translation(SimilarResume);
