import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AdaptiveViewFilter from 'src/components/Search/Vacancy/Filters/AdaptiveViewFilter';
import translation from 'src/components/translation';
import { ClusterKey } from 'src/types/search/common/clusters';

const TrlKeys = {
    title: 'clusters.educationLevel',
};

const Education: TranslatedComponent = ({ trls }) => {
    return <AdaptiveViewFilter title={trls[TrlKeys.title]} name={ClusterKey.Education} />;
};

export default translation(Education);
