import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AdaptiveViewFilter from 'src/components/Search/Vacancy/Filters/AdaptiveViewFilter';
import translation from 'src/components/translation';
import { ClusterKey } from 'src/types/search/common/clusters';

const TrlKeys = {
    title: 'novafilters.exclusion',
};

const Labels: TranslatedComponent = ({ trls }) => {
    return <AdaptiveViewFilter name={ClusterKey.Label} title={trls[TrlKeys.title]} />;
};

export default translation(Labels);
