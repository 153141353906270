import { FC } from 'react';

import { Checkbox } from '@hh.ru/magritte-ui';

import FilterItem from 'src/components/Search/Common/Filters/FilterItem';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import SwitchItem from 'src/components/Search/Vacancy/Filters/FilterSwitchItem';
import { useSelector } from 'src/hooks/useSelector';
import { ClusterKey } from 'src/types/search/common/clusters';

const AcceptTemporary: FC = () => {
    const applyFilter = useApplyFilter(ClusterKey.AcceptTemporary);

    const cluster = useSelector((state) => state.searchClusters[ClusterKey.AcceptTemporary]);
    const { value, group } = {
        group: Object.values(cluster?.groups || {}).pop(),
        value: Boolean(cluster?.selectedValues),
    };

    if (!group) {
        return null;
    }

    return (
        <MobileViewControl
            mobileView={
                <SwitchItem
                    title={group.title}
                    checked={value}
                    onClick={() => applyFilter(!value)}
                    dataQa={`serp__novafilter-${ClusterKey.AcceptTemporary}-${group.id}`}
                    withoutSpacing
                />
            }
        >
            <FilterItem
                left={
                    <Checkbox
                        name={ClusterKey.AcceptTemporary}
                        value={group.id}
                        dataQaCheckbox={`serp__novafilter-${ClusterKey.AcceptTemporary}-${group.id}`}
                        onChange={() => applyFilter(!value)}
                        checked={value}
                    />
                }
                title={group.title}
                disabled={group.disabled}
                count={group.count}
            />
        </MobileViewControl>
    );
};

export default AcceptTemporary;
