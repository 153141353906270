import { useCallback, useRef, useState } from 'react';

import { Checkbox } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterItem from 'src/components/Search/Common/Filters/FilterItem';
import FilterList from 'src/components/Search/Common/Filters/FilterList';
import FilterWrapper from 'src/components/Search/Common/Filters/FilterWrapper';
import LinkMore from 'src/components/Search/Common/Filters/LinkMore';
import MobileViewControl from 'src/components/Search/Common/Filters/MobileViewControl';
import { useApplyFilter } from 'src/components/Search/Common/Filters/hooks/useApplyFilter';
import { useFilterGroups } from 'src/components/Search/Common/Filters/hooks/useFilterGroups';
import { isMetroCheckedOrIndeterminate } from 'src/components/Search/Common/Filters/utils';
import { hasClusterGroups } from 'src/components/Search/Common/Filters/utils/hasClusterGroups';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EMPTY_CLUSTER } from 'src/models/search/common/clusters';
import {
    ClusterKey,
    MetroType,
    SearchVacancyMetroClusterGroup,
    SearchVacancyMetroCluster,
} from 'src/types/search/common/clusters';

import LineIcon from 'src/components/Search/Vacancy/Filters/Metro/LineIcon';
import MetroMobile from 'src/components/Search/Vacancy/Filters/Metro/Mobile';
import PointIcon from 'src/components/Search/Vacancy/Filters/Metro/PointIcon';
import { sortMetroByTitleDesc } from 'src/components/Search/Vacancy/Filters/Metro/utils/sort';

import styles from './styles.less';

const TrlKeys = {
    title: 'searchvacancy.clusters.metro',
    inputPlaceholder: 'search.clusters.input.placeholder',
};

const Metro: TranslatedComponent = ({ trls }) => {
    const applyFilter = useApplyFilter(ClusterKey.Metro);

    const orders = useSelector((state) => state.searchClustersOrder[ClusterKey.Metro]);
    const cluster = (useSelector((state) => state.searchClusters[ClusterKey.Metro]) ||
        EMPTY_CLUSTER) as SearchVacancyMetroCluster;
    const { groups, selectedValues } = cluster;

    const [isExpanded, setIsExpanded] = useState(false);
    const { items, breakpoint } = useFilterGroups(
        groups,
        selectedValues.length,
        orders,
        isExpanded,
        sortMetroByTitleDesc
    );

    const handleMetro = useCallback(
        (metro: string, group: SearchVacancyMetroClusterGroup) => {
            let values = selectedValues.slice();
            const index = values.indexOf(metro);
            if (index !== -1) {
                values.splice(index, 1);
            } else {
                // if use line filter
                // delete all station for selected line
                if (group.type === MetroType.Line) {
                    values = values.filter((item) => item.split('.')[0] !== group.lineId);
                }
                // if use station filter - delete line
                if (group.type === MetroType.Station) {
                    const [line] = metro.split('.');
                    values = values.filter((item) => item !== line);
                }
                values.push(metro);
            }
            applyFilter(values);
        },
        [applyFilter, selectedValues]
    );

    const scrollableContainerRef = useRef<HTMLUListElement>(null);

    if (!hasClusterGroups(cluster)) {
        return null;
    }

    return (
        <MobileViewControl mobileView={<MetroMobile title={trls[TrlKeys.title]} />}>
            <FilterWrapper filterKey={ClusterKey.Metro} title={trls[TrlKeys.title]}>
                <FilterList ref={scrollableContainerRef} expanded={isExpanded} len={items.length}>
                    {(items as SearchVacancyMetroClusterGroup[]).map((metro, index) => {
                        const { id, type, title, color, count, disabled } = metro;
                        if (index >= breakpoint && !isExpanded) {
                            return null;
                        }
                        const { checked, indeterminate } = isMetroCheckedOrIndeterminate(
                            id,
                            selectedValues,
                            color,
                            type
                        );
                        return (
                            <FilterItem
                                key={id}
                                left={
                                    <Checkbox
                                        value={id}
                                        name={ClusterKey.Metro}
                                        onChange={({ target }) => {
                                            handleMetro(target.value, metro);
                                        }}
                                        checked={checked}
                                        indeterminate={indeterminate}
                                        dataQaCheckbox={`serp__novafilter-metro-${id}`}
                                    />
                                }
                                title={
                                    <div className={styles.metroTitleContainer}>
                                        {type === MetroType.Station && <PointIcon color={color} containerSize={22} />}
                                        {type === MetroType.Line && <LineIcon color={color} containerSize={22} />}
                                        {title}
                                    </div>
                                }
                                count={count}
                                disabled={disabled}
                            />
                        );
                    })}
                </FilterList>
                {items.length > breakpoint && (
                    <LinkMore
                        length={items.length - breakpoint}
                        expanded={isExpanded}
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                        }}
                    />
                )}
            </FilterWrapper>
        </MobileViewControl>
    );
};

export default translation(Metro);
